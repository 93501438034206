<template>
  <div id="report">
    <Hero/>
    <div class="report-section" v-if="loaded">
      <h1>{{report.location_name}} - {{report.date | shortDate }}</h1>
      <div class="stars">
        <i class="material-icons" v-for="item in this.getFullStars(report.rating)" :key="item+'noborder'" aria-label="icon star rating">star_rate</i>
        <i class="material-icons" v-for="item in this.getEmptyStars(report.rating)" :key="item+'border'" aria-label="icon star border">star_border</i>
      </div>
      <div class="summary-div">
        <div>
          <h3>Conditions</h3>
          <div class="summary">
            <p>Fishing Conditions: {{getRatingText(report.rating)}}</p>
            <p>Temp: {{report.temp}} &#x2109;</p>
            <p>Flow: {{report.flow}} CFS</p>
            <p>Clarity: {{report.clarity}}</p>
          </div>
        </div>
      </div>
      <p class="description">{{report.description}}</p>
      <h1>Hatches</h1>
      <p class="hatch-subtext">Colorado's rivers, creeks and streams offer the fly fishing angler a myriad of great insect hatches throughout the season. To take out a little guesswork, you can consult this fly hatch chart before you head out. Click <router-link to="/hatch-chart">here</router-link> to see our full hatch chart.</p>
      <div class="hatches">
        <div v-for="hatch in report.hatches" :key="hatch">
          
          <img :src="getHatch(hatch).url">
          <h3>{{hatch}}</h3>
        </div>
      </div>
      <h1>Flies</h1>
      <div class="hatches">
        <div v-for="fly in report.flies" :key="fly">
          
          <img :src="getFly(fly).url">
          <h3>{{fly}}</h3>
        </div>
      </div>
    </div>
    <BookNow/>
  </div>
</template>

<script>
import api from '@/api'
import Hero from '@/components/Hero.vue'
import BookNow from '@/components/BookNow.vue'
export default {
  components: { 
    Hero, 
    BookNow
  },
  data() {
    return {
      flies: [],
      hatches: [],
      loaded: false,
      report: {},
      ratings: [
        'Stay Home',
        'Poor',
        'Fair',
        'Good',
        'Great'
      ]
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  async created () {
    const getReport = await api.get(`/reports/${this.$route.params.id}`)
    const getFlies = await api.get('/flies')
    const getHatches = await api.get('/hatches')
    this.report = getReport
    this.flies = getFlies.flies
    this.hatches = getHatches.hatches
    this.loaded = true
  },
  methods: {
    getFly(name){
      return this.flies.find(f => f.fly_name === name )
    },
    getFullStars(value) {
      return [...Array(parseInt(value)).keys()];
    },
    getHatch(name){
      return this.hatches.find(h => h.hatch_name === name )
    },
    getEmptyStars(value) {
      return [...Array(Math.abs(parseInt(value) - 5)).keys()]
    },
    getRatingText(value) {
      return this.ratings[parseInt(value) - 1]
    }
  }
}
</script>


<style lang="scss">
#report {
  .report-section {
    width: 65%;
    margin: auto;
    h1 {
      text-align: left;
      margin-top: 50px;
    }
    p, .stars {
      text-align: left;
    }
    .description {
      white-space: pre-line;
    }
    .material-icons {
      color: #3C6E71;
      font-size: 40px;
    }
    .summary {
      background-color: #3C6E71;
      color: white;
      width: 400px;  
      padding: 30px;  
      filter: drop-shadow(3px 3px 3px black);
    }
    .summary-div {
      justify-content: space-around;
      flex-wrap: wrap;
      margin: 40px 0;
      h3 {
        text-align: left;
      }
    }
    .hatch-subtext {
      margin: 20px 0px;
    }
    .hatches {
      display: flex;
      flex-wrap: wrap;
      img {
        height: 200px;
      }
    }
    a {
      color: #3C6E71;
      text-decoration: underline;
      font-weight: 600;
    }
  }
  @media only screen and (max-width: 500px) {
    .report-section {
      width: 90%;
      margin: auto;
    }
  }
}
</style>